import React from 'react';
import {
  Route, Switch
} from 'react-router-dom';
import PropTypes from 'prop-types';

import Home from './Home';
import About from './About';
import Work from './Work';
import Laboratory from './Laboratory';
import Contact from './Contact';
import NotFound from './NotFound';

const Main = ({ phoenixData, setDocumentTitle }) => {
  return (
    <main className="main-content">
      <Switch>
        <Route exact path="/">
          <Home data={phoenixData.home} setDocumentTitle={setDocumentTitle} />
        </Route>
        <Route path="/about">
          <About setDocumentTitle={setDocumentTitle} />
        </Route>
        <Route path="/work">
          <Work data={phoenixData.work} setDocumentTitle={setDocumentTitle} />
        </Route>
        <Route path="/laboratory">
          <Laboratory data={phoenixData.lab} setDocumentTitle={setDocumentTitle} />
        </Route>
        <Route path="/contact">
          <Contact setDocumentTitle={setDocumentTitle} />
        </Route>
        <Route >
          <NotFound />
        </Route>
      </Switch>
    </main>
  );
}

Main.propTypes = {
  phoenixData: PropTypes.shape(),
  setDocumentTitle: PropTypes.func,
}

export default Main;
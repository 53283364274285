import React, { Component } from "react";

import "../css/App.css";

import Header from "./Header";
import Main from "./Main";
import Footer from "./Footer";
import Menu from "./Menu";
import ErrorBoundary from "./ErrorBoundary";
import Loading from "./Main/Loading";
import LoadingError from "./Main/LoadingError";

class App extends Component {
  state = {
    isMenuShown: false,
    phoenixData: {},
    loading: true,
    error: false,
  };

  componentDidMount() {
    const phoenix = sessionStorage.getItem("phoenix");

    if (phoenix) {
      this.setState({
        phoenixData: JSON.parse(phoenix),
        loading: false,
      });
    } else {
      fetch("https://phoenixjaymes.com/assets/data/phoenix/get-phoenix.php")
        .then((reponse) => reponse.json())
        .then((responseData) => {
          sessionStorage.setItem("phoenix", JSON.stringify(responseData.data));
          this.setState({
            phoenixData: responseData.data,
            loading: false,
          });
        })
        .catch((error) => {
          console.log("Error fetching and parsing data", error);

          this.setState({
            loading: false,
            error: true,
          });
        });
    }
  }

  handleMenuButton = () => {
    this.setState({ isMenuShown: !this.state.isMenuShown });
  };

  setDocumentTitle = (title) => {
    document.title = `${title} | PhoenixJaymes Web Development`;
  };

  renderLoading = () => <Loading />;

  renderError = () => <LoadingError />;

  renderApp() {
    const { phoenixData } = this.state;
    return (
      <div>
        <Header
          isMenuShown={this.state.isMenuShown}
          handleMenuButton={this.handleMenuButton}
        />
        <ErrorBoundary>
          <Main
            phoenixData={phoenixData}
            setDocumentTitle={this.setDocumentTitle}
          />
        </ErrorBoundary>
        <Footer />
        <Menu
          isMenuShown={this.state.isMenuShown}
          handleMenuButton={this.handleMenuButton}
        />
      </div>
    );
  }

  render() {
    const { loading, error } = this.state;

    if (loading) {
      return this.renderLoading();
    }

    if (error) {
      return this.renderError();
    }

    return this.renderApp();
  }
}

export default App;

import React from 'react';
import { Link } from 'react-router-dom';

import Hero from './Hero';
import Action from './Action';
import Quote from './Quote';

const NotFound = props => {
  const quote = "Every great dream begins with a dreamer. Always remember, you have within you the strength, the patience, and the passion to reach for the stars to change the world.";
  const author = "Harriet Tubman";

  return (
    <div>
      <Hero />

      <section class="content">
        <h2 class="content__heading">Not Found</h2>

        <div class="inner">
          <p>Well, you found it. The hidden page of nothing. Now please move along. <Link to="/">Somewhere that's not here</Link></p>
          
        </div>
      </section>

      <Action page="/" label="Home" />
  
      <section className="spacer">&nbsp;</section>

      <Quote quote={quote} author={author} />

    </div>
  );
}

export default NotFound;
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Hero from './Hero';
import Projects from './Projects';
import Action from './Action';
import Quote from './Quote';
import ScrollToTop from './ScrollToTop';

const Work = ({ data, setDocumentTitle }) => {
    const quote = "It has become appallingly obvious that our technology has exceeded our humanity.";
    const author = "?";

    useEffect(() => {
      setDocumentTitle('Work');
    }, [setDocumentTitle]);

    return (
      <div>
        <ScrollToTop />
        <Hero />

        <Projects heading={data.heading} text={data.text} type="work" data={data.projects} />

        <Action page="/laboratory" label="Laboratory" />
  
        <section className="spacer">&nbsp;</section>

        <Quote quote={quote} author={author} />

      </div>
    );
};

Work.propTypes = {
  data: PropTypes.shape(),
  setDocumentTitle: PropTypes.func,
};

export default Work;
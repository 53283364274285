import React from "react";
import PropTypes from "prop-types";

const Projects = ({ heading, text, type, data }) => {
  const projectsItems = data.map((project) => {
    let imgPath;
    if (type === "work") {
      imgPath = "https://phoenixjaymes.com/assets/img/projects/";
    } else {
      imgPath = "https://phoenixjaymes.com/assets/img/labs/";
    }

    return (
      <figure key={project.id} className="projects__figure">
        <a
          href={`https://phoenixjaymes.com/${project.path}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={`${imgPath}${project.img}`} alt={project.path} />
        </a>
        <figcaption className="projects__caption">{project.name}</figcaption>
      </figure>
    );
  });

  return (
    <section className="content">
      <h2 className="content__heading">{heading}</h2>

      <p className="content__text">{text}</p>

      <div className="projects">
        <div className="projects__wrap">{projectsItems}</div>
      </div>
    </section>
  );
};

Projects.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
  data: PropTypes.array,
};

export default Projects;

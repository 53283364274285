import React from 'react';

const Footer = props => {
  const curYear = new Date().getFullYear();
  return (
    <footer className="main-footer">
      <div className="main-footer__wrap">
        <small>&copy; {curYear} PhoenixJaymes</small>
      </div>
    </footer>
  );
}

export default Footer;
import React from 'react';

import Hero from './Hero';
import Quote from './Quote';

const Loading = props => {
  const quote = "My mission in life is not merely to survive, but to thrive; and to do so with some passion, some compassion, some humor, and some style.";
  const author = "Maya Angelou";

  return (
    <div>
      <Hero />

      <section className="content">
        <h2 className="content__heading">Loading</h2>

        <div className="inner">
          <p>Developer based in Portland, OR. Ultimately what I do is solve problems and create things. Tell me your problems or what you want created and I will find a way to make it happen.</p>
          <p>I regularly use HTML5, CSS3, JavaScript, jQuery, PHP, MySql, Composer, Angular, Twig, Composer, Gulp, Git, Photoshop, and Illustrator to create my projects.</p>
          <p>I enjoy languages so in my free time I am learning German and Dutch. In the future I plan to add more Germanic langauges.</p>
        </div>
      </section>

      {/* <Action page="/contact" label="contact" /> */}
  
      <section className="spacer">&nbsp;</section>

      <Quote quote={quote} author={author} />

    </div>
  );
}

export default Loading;
import React from 'react';
import PropTypes from 'prop-types';

const Quote = ({quote, author}) => {
  return (
    <section className="content quote">
      <div className="inner">          
          <blockquote className="quote__text">{quote}</blockquote>
          <p className="quote__author">{author}</p>
        </div>
    </section>
  );
}

Quote.propTypes = {
  quote: PropTypes.string,
  author: PropTypes.string
}

export default Quote;
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const Action = ({page, label}) => {
  
  return (
    <section className="content action">
      <div className="inner">     
        <p className="action__text">Interested in working with me, need to develop a project or just want to chat?<br/>Feel free to write me. I would love to hear from you.</p>
        <Link to={`${page}`} className="action__button">{label.toUpperCase()}</Link>
      </div>
    </section>
  );
}

Action.propTypes = {
  page: PropTypes.string
}

export default Action;
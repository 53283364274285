import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Hero from './Main/Hero';
import Action from './Main/Action';
import Quote from './Main/Quote';

export default class ErrorBoundary extends Component {
  state = {
    hasError: false,
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      const quote = "My mission in life is not merely to survive, but to thrive; and to do so with some passion, some compassion, some humor, and some style.";
      const author = "Maya Angelou";

      return (
        <div>
          <Hero />

          <section class="content">
            <h2 class="content__heading">An Has Error Occurred</h2>

            <div class="inner">
              <p>Developer based in Portland, OR. Ultimately what I do is solve problems and create things. Tell me your problems or what you want created and I will find a way to make it happen.</p>
              <p>I regularly use HTML5, CSS3, JavaScript, jQuery, PHP, MySql, Composer, Angular, Twig, Composer, Gulp, Git, Photoshop, and Illustrator to create my projects.</p>
              <p>I enjoy languages so in my free time I am learning German and Dutch. In the future I plan to add more Germanic langauges.</p>
            </div>
          </section>

          <Action page="/contact" label="contact" />
      
          <section className="spacer">&nbsp;</section>

          <Quote quote={quote} author={author} />

        </div>
      )
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.element,
};

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const Header = ({isMenuShown, handleMenuButton}) => {
  const changeIcon = isMenuShown ? 'menu-btn-click' : '';
  
  return (
    <header className="main-header">
      <div className="main-header__wrap">
        <p id="brand" className="main-header__brand" style={ isMenuShown?{'visibility': 'hidden'} : {'visibility': 'visible'} }>
          <Link to="/">P<span>hoenix</span>J<span>aymes</span></Link>
        </p>
        <p id="btn-menu" className="main-header__btn" onClick={handleMenuButton}><span className={changeIcon}>&nbsp;</span></p>
      </div>
    </header>
  );
}

Header.propType = {
  isMenuShown: PropTypes.bool.isRequired,
  handleMenuButton: PropTypes.func.isRequired
}

export default Header;
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Hero from './Hero';
import Projects from './Projects';
import Action from './Action';
import Quote from './Quote';
import ScrollToTop from './ScrollToTop';

const Laboratory = ({ data, setDocumentTitle }) => {
  const quote = "Darkness cannot drive out darkness; only light can do that. Hate cannot drive out hate; only love can do that.";
  const author = "Dr. Martin Luther King Jr.";

  useEffect(() => {
    setDocumentTitle('Laboratory');
  }, [setDocumentTitle]);

  return (
    <div>
      <ScrollToTop />
      <Hero />

      <Projects heading={data.heading} text={data.text} type="lab" data={data.projects} />

      <Action page="/work" label="work" />

      <section className="spacer">&nbsp;</section>

      <Quote quote={quote} author={author} />

    </div>
  );
};

Laboratory.propTypes = {
  data: PropTypes.shape(),
  setDocumentTitle: PropTypes.func,
};


export default Laboratory;
import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

const Menu = ({isMenuShown, handleMenuButton}) => {
  const showMenu = isMenuShown ? 'js-menu-on' : 'js-menu-off';

  return (
    <nav id="fullscreen-menu" className={`main-nav ${showMenu}`}>
      <ul className="main-menu">
        <li ><NavLink to="/" exact onClick={handleMenuButton}>Home</NavLink></li>
        <li ><NavLink to="/about" onClick={handleMenuButton}>About</NavLink></li>
        <li ><NavLink to="/laboratory" onClick={handleMenuButton}>Laboratory</NavLink></li>
        <li ><NavLink to="/work" onClick={handleMenuButton}>Work</NavLink></li>
        <li ><NavLink to="/contact" onClick={handleMenuButton}>Contact</NavLink></li> 
      </ul>
    </nav>
  );
}

Menu.propTypes = {
  isMenuShown: PropTypes.bool.isRequired,
  handleMenuButton: PropTypes.func.isRequired
}

export default Menu;
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Hero from './Hero';
import Action from './Action';
import Quote from './Quote';
import ScrollToTop from './ScrollToTop';

const Contact = ({ setDocumentTitle }) => {
  const quote = "In nature, nothing is perfect and everything is perfect. Trees can be contorted, bent in weird ways, and they're still beautiful.";
  const author = "Alice Walker";

  useEffect(() => {
    setDocumentTitle('Contact');
  }, [setDocumentTitle]);

  return (
    <div>
      <ScrollToTop />
      <Hero />

      <section className="content">
        <h2 className="content__heading">Contact</h2>

        <div className="inner">

          <form method="post" action="#" name="frmContact" id="frmContact" className="contact-form">
            <p className="contact-text">Need a project or just want to chat<br />Feel free to write me.</p>
            <label className="contact-form__label" htmlFor="txtName">Name:</label>
            <input className="contact-form__text" type="text" name="txtName" id="txtName" placeholder="Name*" maxLength="40" required />

            <label className="contact-form__label" htmlFor="txtEmail">Email:</label>
            <input className="contact-form__text" type="email" name="txtEmail" id="txtEmail" placeholder="Email*" maxLength="50" required />

            <label className="contact-form__label" htmlFor="txtPhone">Phone:</label>
            <input className="contact-form__text" type="text" name="txtPhone" id="txtPhone" placeholder="Phone" maxLength="20" />

            <label className="contact-form__label" htmlFor="txtComment">Comment:</label>
            <textarea className="contact-form__textarea" name="txtComment" id="txtComment" placeholder="Message*" required></textarea>

            <fieldset style={{display:'none'}}>
                <p>Please leave this field blank</p>
                <label htmlFor="txtAddress">Address:</label>
                <input type="text" name="txtAddress" id="txtAddress" />
            </fieldset>
            <input className="contact-form__submit" type="submit" name="frmSubmit" id="frmSubmit" value="Submit" />
          </form>

        </div>
      </section>

      <Action page="/laboratory" label="laboratory" />
  
      <section className="spacer">&nbsp;</section>

      <Quote quote={quote} author={author} />
    </div>
  );
}

Contact.propTypes = {
  setDocumentTitle: PropTypes.func,
}

export default Contact;
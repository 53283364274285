import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Hero from './Hero';
import Projects from './Projects';
import Action from './Action';
import Quote from './Quote';
import ScrollToTop from './ScrollToTop';

const Home = ({ data, setDocumentTitle }) => {
  const { lab, work } = data;

  const quote = "People assume that time is a strict progression of cause-and-effect... but actually, from a non-linear, non-subjective viewpoint, it's more like a big ball of wibbly-wobbly... timey-wimey... stuff";
  const author = "The Tenth Doctor";

  useEffect(() => {
    setDocumentTitle('Home');
  }, [setDocumentTitle]);

  return (
    <div>
      <ScrollToTop />
      <Hero />

      <Projects heading={lab.heading} text={lab.text} type="lab" data={lab.projects} />      

      <section className="content">
        <h2 className="content__heading">About</h2>

        <div className="inner">
          <p>Developer based in Portland, OR. Ultimately what I do is solve problems and create things. Tell me your problems or what you want created and I will find a way to make it happen.</p>
        </div>
      </section>
      
      <Projects heading={work.heading} text={work.text} type="work" data={work.projects} />

      <Action page="/contact" label="contact" />

      <section className="spacer">&nbsp;</section>

      <Quote quote={quote} author={author} />

    </div>
  );
};

Home.propTypes = {
  data: PropTypes.shape(),
  setDocumentTitle: PropTypes.func,
}

export default Home;